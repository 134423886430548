<template>
	<section class="dashboard--trackers">
		<header class="dashboard__header">
			<h3 class="heading3">Trackers</h3>
			<a v-if="false" href="#" class="dashboard__actions"></a>
            
            <div v-if="false" class="sort-section">
                <a class="sort-section__up" href="#"></a>
                <a class="sort-section__down" href="#"></a>
            </div>
		</header>

		<div class="dashboard__content">
			<div v-if="loading">Loading...</div>
			<div class="text-center" style="width: 100%" v-if="!loading && isEmpty">
				<h4 class="heading4 mb-4">Get Deals Done</h4>
				<p>Add startups to trackers, perform diligence and advance to a pilot</p>
				<b-button class="btn-primary btn-lg" @click="$refs.addTracker.open()">Create Tracker</b-button>
			</div>
			<ul class="dashboard__stats" v-if="!loading && !isEmpty">
				<li>
					<div class="dashboard__stats__counter">
						<animated-number
							:value="trackersCount.added"
                            :formatValue="stringHelper.toLocale"
							:duration="500"
							:round="true"
						/>
					</div>
					<p>{{stringHelper.getNoun('Tracker', trackersCount.added)}} added by {{ $store.state.user.company_name }} users for an all-time total of {{ trackersCount.all }}</p>
				</li>
				<li>
					<div class="dashboard__stats__counter">
						<animated-number
							:value="trackersWithActivityCount"
                            :formatValue="stringHelper.toLocale"
							:duration="500"
							:round="true"
						/>
					</div>
					<p>{{stringHelper.getNoun('Tracker', trackersWithActivityCount)}} with activity</p>
				</li>
				<li>
					<div class="dashboard__stats__counter">
						<animated-number
							:value="trackersWithNoStartupsCount"
                            :formatValue="stringHelper.toLocale"
							:duration="500"
							:round="true"
						/>
					</div>
					<p>{{stringHelper.getNoun('Tracker', trackersWithNoStartupsCount)}} with no startups</p>
				</li>
			</ul>

			<div class="dashboard__content__inner" v-if="!loading && !isEmpty">
				<b-row>
					<b-col sm="12">
						<h4 class="heading4">Most active trackers</h4>
						<ul class="rows-list rows-list--secondary rows-list--cards">
							<li v-for="tracker in mostActiveTrackers" :key="tracker.id">
								<div class="rows-list--cards__content">
                                    <div>
                                        <h5 class="heading5"><a :href="'/trackers/' + tracker.id" target="_blank">{{ tracker.name }}</a></h5>
                                        <p>{{ tracker.cnt.toLocaleString() }} {{stringHelper.getNoun('update', tracker.cnt)}}</p>
                                    </div>
								</div>
                                <div class="rows-list--cards__footer">
                                    <div class="rows-list--cards__footer__inner">
                                        {{tracker.type}} • Last modified {{ tracker.last_modified }}
                                    </div>
                                </div>
							</li>
						</ul>
					</b-col>
				</b-row>
			</div>
		</div>

		<edit-tracker
			v-if="$store.state.canLoadGlobalComponents"
			ref="addTracker"
			prefix="-home-trackers"
			:folder-id="0"
			v-on:trackerSaved="void updateAll()"
		>
		</edit-tracker>
	</section>
</template>
<script>
import AnimatedNumber from 'animated-number-vue';
import dashboard from '@/services/dashboard.service';

export default {
    name: 'Trackers',

    props: {
        filters: Object,
    },

    components: {
        editTracker: () => import('@/components/trackers/trackerEdit/TrackerEdit.vue'),
        AnimatedNumber
    },

	data() {
		return {
			loading: false,
            trackersCount: {
                all: 0,
                added: 0
            },
            mostActiveTrackers: [],
            trackersWithActivityCount: 0,
            trackersWithNoStartupsCount: 0
		};
	},


    watch: {
        filters() {
            this.updateAll();
        },
    },

    mounted() {
        this.$store.watch(state => {
            if (state.accountType.toString() !== 'premium') {
                void this.updateAll();
            }
        });
        void this.updateAll();
    },

	methods: {
		async updateAll() {
			this.loading = true;
			await Promise.all([
				this.fetchTrackersCount(),
				this.fetchMostActiveTrackers(),
				this.fetchTrackersWithActivityCount(),
				this.fetchTrackersWithNoStartupsCount()
			]);
			this.loading = false;
		},
		async fetchTrackersCount() {
            const response = await dashboard.getWidgetData('TrackersCount', this.filters);
			this.trackersCount = response.data;
		},
        async fetchTrackersWithActivityCount() {
            const response = await dashboard.getWidgetData('TrackersWithActivityCount', this.filters);
            this.trackersWithActivityCount = response.data;
        },
		async fetchTrackersWithNoStartupsCount() {
            const response = await dashboard.getWidgetData('TrackersWithNoStartupsCount', this.filters);
			this.trackersWithNoStartupsCount = response.data;
		},
        async fetchMostActiveTrackers() {
            const response = await dashboard.getWidgetData('MostActiveTrackers', this.filters);
            this.mostActiveTrackers = response.data;
        },
	},

	computed: {
		isEmpty() {
			return (
				+this.trackersCount.all === 0 &&
				+this.trackersCount.added === 0 &&
				this.mostActiveTrackers.length === 0 &&
				+this.trackersWithActivityCount === 0 &&
				+this.trackersWithNoStartupsCount === 0
			);
		}
	}
};
</script>
